body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
  background-color: #ffffff;
}

img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto;
}

header {
  background: #111;
  color: #fff;
  text-align: center;
  padding: 2rem 1rem;
}

header h1 {
  font-size: 2.5rem;
  margin: 0;
}

header p {
  font-size: 1.2rem;
  margin: 1rem 0 0;
}

section {
  padding: 2rem 1rem;
  max-width: 900px;
  margin: 0 auto;
}

section h2 {
  color: #111;
  margin-bottom: 1rem;
}

.cta {
  text-align: center;
  margin: 2rem 0;
}

.cta a {
  display: inline-block;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  color: #fff;
  background: #0078ff;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.cta a:hover {
  background: #005bb5;
}

form input[type="email"] {
  padding: 0.8rem;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  margin: 0.5rem auto;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  display: block;
  margin: 1rem auto;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background: #0078ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

form button:hover {
  background: #005bb5;
}

.footer {
  text-align: center;
  padding: 1rem;
  background: #111;
  color: #fff;
  margin-top: 2rem;
}

.footer a {
  color: #0078ff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  header h1 {
    font-size: 2rem;
  }

  header p {
    font-size: 1rem;
  }

  .cta a {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
  }

  form input[type="email"], form button {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}